<template>
  <b-input
    :value="values[field.id]"
    size="is-small"
    type="email"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? getTranslation(`${field.id}_placeholder`, field.properties.basic.placeholder) : ''"
    :use-html5-validation="false"
    @input="handleInput"
    @blur="handleBlur()"
    v-on="fieldEvents"
  />
</template>
  
<script >
import { useLocalizationStore } from '@/store/localizationStore';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const localizationStore = useLocalizationStore();
  const {
    getTranslation
  } = localizationStore;
  const handleInput = value => {
    emit('update', {
      value
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  const handleBlur = () => {
    emit('blur');
  };
  return {
    getTranslation,
    handleInput,
    handleBlur
  };
};
export default __sfc_main;
</script>
